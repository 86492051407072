import * as React from 'react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { cn } from '~/common/utils/styles';
import type { Link } from '@remix-run/react';
import { LoadingIndicator } from '~/cds/LoadingIndicator';

export const buttonVariants = cva(
    'rounded-full w-fit -tracking-[0.02em] font-medium flex items-center gap-2.5 justify-center transition-all duration-300 ease-in-out',
    {
        variants: {
            variant: {
                primary:
                    'bg-black text-white hover:bg-gray-800 active:bg-gray-700 disabled:bg-gray-100 disabled:text-gray-300',
                secondary:
                    'bg-black/[7%] text-black hover:bg-black/[16%] active:bg-black/[27%] disabled:bg-gray-100 disabled:text-gray-300',
                tertiary:
                    'bg-white text-black hover:bg-gray-25 active:bg-gray-50 disabled:bg-white disabled:text-gray-300',
                outlined:
                    'bg-white border border-gray-100 text-black hover:border-black active:border-black active:bg-black active:bg-opacity-[7%] disabled:border-gray-50  disabled:bg-black disabled:bg-opacity-[0%] disabled:text-gray-300',
                negative:
                    'bg-red-600 text-white hover:bg-red-700 active:bg-red-800 disabled:bg-gray-100 disabled:text-gray-300',
            },
            size: {
                sm: 'h-9 px-5 py-2.5 text-sm leading-4',
                md: 'h-11 px-6 py-3 text-base leading-5',
                lg: 'h-14 px-8 py-4 text-lg leading-6',
            },
        },
        defaultVariants: {
            variant: 'primary',
            size: 'md',
        },
    }
);

const iconOnlyVariants = {
    sm: ' py-2.5 px-2 w-9',
    md: ' p-3 w-11',
    lg: ' p-4 w-14',
};

const selectedVariants = {
    primary: ' bg-gray-700',
    secondary: ' bg-black text-white',
    tertiary: ' bg-gray-50',
    outlined: ' border-black bg-black bg-opacity-[7%]',
    negative: ' bg-red-800',
};

export interface ButtonProps
    extends React.ButtonHTMLAttributes<
            HTMLButtonElement | HTMLAnchorElement | HTMLDivElement
        >,
        VariantProps<typeof buttonVariants> {
    children?: React.ReactNode;
    loading?: boolean;
    selected?: boolean;
    leftSideIcon?: React.ReactNode;
    rightSideIcon?: React.ReactNode;
    iconOnly?: React.ReactNode;
    as?: 'button' | typeof Link | 'div';
    to?: string;
}
export const Button: React.FC<ButtonProps> = React.forwardRef(
    (
        {
            className = '',
            variant,
            size,
            loading,
            selected,
            leftSideIcon,
            rightSideIcon,
            iconOnly,
            children,
            as = 'button',
            to = '',
            ...rest
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref: React.ForwardedRef<any> //React.ForwardedRef<HTMLButtonElement>
    ) => {
        if (iconOnly) {
            className += iconOnlyVariants[size ?? 'md'];
        }

        if (selected) {
            className += selectedVariants[variant ?? 'primary'];
        }

        const As = as;

        return (
            <As
                {...rest}
                to={to}
                className={cn(buttonVariants({ variant, size }), className)}
                ref={ref}
            >
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    iconOnly ?? (
                        <>
                            {leftSideIcon}
                            {children}
                            {rightSideIcon}
                        </>
                    )
                )}
            </As>
        );
    }
);

Button.displayName = 'Button';
